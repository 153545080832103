import axios, { AxiosInstance } from 'axios';
import { PlaceResult, LatLngLiteral } from '@/types';
import {mockData} from "@/utils/mockOutScraper.ts";

export type OutscraperPlaceResult = {
  query: string
  name: string
  name_for_emails: string
  place_id: string
  google_id: string
  full_address?: null | string
  borough?: null | string
  street?: null | string
  postal_code: string
  area_service: boolean
  country_code?: null | string
  country?: null | string
  city?: null | string
  us_state?: null | string
  state?: null | string
  plus_code: any
  latitude: number
  longitude: number
  h3: string
  time_zone: string
  popular_times: any
  site?: null | string
  phone?: null | string
  type: string
  logo?: null | string
  description?: null | string
  typical_time_spent: any
  located_in?: null | string
  located_google_id?: null | string
  category: string
  subtypes: string
  posts: any
  reviews_tags: any
  rating?: null | number
  reviews?: null | number
  photos_count?: null | number
  cid: string
  reviews_link?: null | string
  reviews_id?: null | string
  photo?: null | string
  street_view?: null | string
  working_hours_old_format?: null | string
  working_hours?: null | WorkingHours
  other_hours?: null | OtherHour[]
  business_status: string
  about: About
  range?: null | string
  reviews_per_score?: null | ReviewsPerScore
  reservation_links?: null | string[]
  booking_appointment_link?: null | string
  menu_link: any
  order_links?: null | string[]
  owner_id?: null | string
  verified: boolean
  owner_title: string
  owner_link?: null | string
  location_link: string
  location_reviews_link: string
  reviews_per_score_1?: string | null
  reviews_per_score_2?: string | null
  reviews_per_score_3?: string | null
  reviews_per_score_4?: string | null
  reviews_per_score_5?: string | null
  located_os_id?: null | string
}

export interface WorkingHours {
  Monday: string
  Tuesday: string
  Wednesday: string
  Thursday: string
  Friday: string
  Saturday: string
  Sunday: string
}

export interface OtherHour {
  online_service_hours?: null | OnlineServiceHours
  "lowe's_garden_center"?: null | LoweSGardenCenter
  garden_center_at_the_home_depot?: null | GardenCenterAtTheHomeDepot
  home_services_at_the_home_depot?: null | HomeServicesAtTheHomeDepot
  penske_truck_rental?: null | PenskeTruckRental
  pro_desk_at_the_home_depot?: null | ProDeskAtTheHomeDepot
  "tool_&_truck_rental_at_the_home_depot"?: null | ToolTruckRentalAtTheHomeDepot
  compact_power_equipment_rental?: null | CompactPowerEquipmentRental
  kitchen_cabinets?: null | KitchenCabinets
  truck_rental_at_the_home_depot?: null | TruckRentalAtTheHomeDepot
}

export interface OnlineServiceHours {
  Monday: string
  Tuesday: string
  Wednesday: string
  Thursday: string
  Friday: string
  Saturday: string
  Sunday: string
}

export interface LoweSGardenCenter {
  Monday: string
  Tuesday: string
  Wednesday: string
  Thursday: string
  Friday: string
  Saturday: string
  Sunday: string
}

export interface GardenCenterAtTheHomeDepot {
  Monday: string
  Tuesday: string
  Wednesday: string
  Thursday: string
  Friday: string
  Saturday: string
  Sunday: string
}

export interface HomeServicesAtTheHomeDepot {
  Monday: string
  Tuesday: string
  Wednesday: string
  Thursday: string
  Friday: string
  Saturday: string
  Sunday: string
}

export interface PenskeTruckRental {
  Monday: string
  Tuesday: string
  Wednesday: string
  Thursday: string
  Friday: string
  Saturday: string
  Sunday: string
}

export interface ProDeskAtTheHomeDepot {
  Monday: string
  Tuesday: string
  Wednesday: string
  Thursday: string
  Friday: string
  Saturday: string
  Sunday: string
}

export interface ToolTruckRentalAtTheHomeDepot {
  Monday: string
  Tuesday: string
  Wednesday: string
  Thursday: string
  Friday: string
  Saturday: string
  Sunday: string
}

export interface CompactPowerEquipmentRental {
  Monday: string
  Tuesday: string
  Wednesday: string
  Thursday: string
  Friday: string
  Saturday: string
  Sunday: string
}

export interface KitchenCabinets {
  Monday: string
  Tuesday: string
  Wednesday: string
  Thursday: string
  Friday: string
  Saturday: string
  Sunday: string
}

export interface TruckRentalAtTheHomeDepot {
  Monday: string
  Tuesday: string
  Wednesday: string
  Thursday: string
  Friday: string
  Saturday: string
  Sunday: string
}

export interface About {
  "From the business"?: null | FromTheBusiness
  "Service options"?: null | ServiceOptions
  Planning?: null | Planning
  Payments?: null | Payments
  Other?: null | Other
  Crowd?: null | Crowd
  Accessibility?: null | Accessibility
  Amenities?: null | Amenities
  Parking?: null | Parking
  Offerings?: null | Offerings
  Recycling?: null | Recycling
  Children?: null | Children
  "Popular for"?: null | PopularFor
  "Dining options"?: null | DiningOptions
  Atmosphere?: null | Atmosphere
}

export interface FromTheBusiness {
  "Identifies as veteran-owned"?: null | boolean
  "Identifies as women-owned"?: null | boolean
  "Identifies as LGBTQ+ owned"?: null | boolean
  "Identifies as Black-owned"?: null | boolean
  "Identifies as Latino-owned"?: null | boolean
  "Small business"?: null | boolean
}

export interface ServiceOptions {
  "Language assistance"?: null | string
  "Online estimates"?: null | boolean
  "Online appointments"?: null | boolean
  "Onsite services"?: null | boolean
  Delivery?: null | boolean
  "Same-day delivery"?: null | boolean
  "Outdoor seating"?: null | boolean
  Takeout?: null | boolean
  "Dine-in"?: null | boolean
  "Curbside pickup"?: null | boolean
  "In-store pickup"?: null | boolean
  "In-store shopping"?: null | boolean
}

export interface Planning {
  "Appointment required"?: null | boolean
  "Quick visit"?: null | boolean
  "Accepts reservations"?: null | boolean
}

export interface Payments {
  "Credit cards"?: null | boolean
  "Cash-only"?: null | boolean
  "Debit cards"?: null | boolean
  Checks?: null | boolean
  "NFC mobile payments"?: null | boolean
}

export interface Other {
  "Identifies as veteran-owned"?: null | boolean
  "LGBTQ+ friendly"?: null | boolean
  "Identifies as women-owned"?: null | boolean
  "Identifies as LGBTQ+ owned"?: null | boolean
  "Identifies as Black-owned"?: null | boolean
  "Identifies as Latino-owned"?: null | boolean
}

export interface Crowd {
  "LGBTQ+ friendly"?: null | boolean
  "Transgender safespace"?: null | boolean
  "College students"?: null | boolean
  Groups?: null | boolean
}

export interface Accessibility {
  "Wheelchair accessible entrance"?: null | boolean
  "Wheelchair accessible parking lot"?: null | boolean
  "Wheelchair accessible restroom"?: null | boolean
  "Wheelchair accessible seating"?: null | boolean
}

export interface Amenities {
  "Gender-neutral restroom"?: null | boolean
  Restroom?: null | boolean
  "Offers tours"?: null | boolean
  "Petting zoo"?: null | boolean
  "Wi-Fi"?: null | boolean
}

export interface Parking {
  "Free parking lot"?: null | boolean
  "Free street parking"?: null | boolean
  "Paid street parking"?: null | boolean
  Parking?: null | boolean
  "On-site parking"?: null | boolean
}

export interface Offerings {
  "Comfort food"?: null | boolean
  Food?: null | boolean
  "Late-night food"?: null | boolean
  "Quick bite"?: null | boolean
  "Gift shop"?: null | boolean
  "Assembly service"?: null | boolean
  "Repair services"?: null | boolean
  "Service guarantee"?: null | boolean
}

export interface Recycling {
  Batteries?: null | boolean
  Electronics?: null | boolean
  "Metal cans"?: null | boolean
  "Hazardous household materials"?: null | boolean
  "Ink cartridges"?: null | boolean
  "Light bulbs"?: null | boolean
  "Plastic bottles"?: null | boolean
}

export interface Children {
  "Good for kids": boolean
  "Good for kids birthday"?: null | boolean
  Playground?: null | boolean
}

export interface PopularFor {
  Lunch: boolean
  Dinner: boolean
  "Solo dining": boolean
}

export interface DiningOptions {
  Lunch: boolean
  Dinner: boolean
  Seating: boolean
}

export interface Atmosphere {
  Casual: boolean
}

export interface ReviewsPerScore {
  "1": number
  "2": number
  "3": number
  "4": number
  "5": number
}

const mockOutScraper : boolean = false;

interface OutscraperResponse {
  data: OutscraperPlaceResult[] | OutscraperPlaceResult[][];
  status: 'Success' | 'Error';
  error?: null | string;
}

export class OutscraperService {
  private api: AxiosInstance;
  private readonly MAX_RETRIES = 3;
  private readonly BASE_DELAY = 2000;
  private readonly BATCH_SIZE = 30;

  constructor(accessToken : string) {
    this.api = axios.create({
      baseURL: import.meta.env.VITE_OUTSCRAPER_API,
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
  }

  private async searchPlacesWithRetry(
    queries: string[],
    location: LatLngLiteral,
    radius: number,
    controller: AbortController,
    retryCount = 0,
  ): Promise<OutscraperPlaceResult[]> {
    if (mockOutScraper) {
      return mockData;
    }

    try {
      const response = await this.api.post<OutscraperResponse>('/', {
        query: queries,
        radius: Math.floor(radius * 1609.34), // Convert miles to meters
        coordinates: `${location.lat},${location.lng}`,
        signal: controller.signal
      });

      if (response.data.status === 'Error') {
        throw new Error(response.data.error || 'API request failed');
      }

      let results: OutscraperPlaceResult[] = [];
      if (Array.isArray(response.data.data)) {
        if (response.data.data.length > 0) {
          if (Array.isArray(response.data.data[0])) {
            // Nested array format - flatten all results
            results = (response.data.data as OutscraperPlaceResult[][]).flat();
          } else {
            // Single array format
            results = response.data.data as OutscraperPlaceResult[];
          }
        }
      }

      return results.filter(result =>
        result &&
        result.place_id &&
        result.business_status !== 'CLOSED_PERMANENTLY' &&
        this.isWithinRadius(
          { lat: result.latitude, lng: result.longitude },
          location,
          radius
        )
      );

    } catch (error) {
      if (axios.isAxiosError(error) && !controller.signal.aborted) {
        const status = error.response?.status;
        const errorMessage = error.response?.data?.errorMessage || error.message;

        if ((status === 429 || status === 500) && retryCount < this.MAX_RETRIES) {
          const delay = this.BASE_DELAY * Math.pow(2, retryCount);
          await new Promise(resolve => setTimeout(resolve, delay));
          return this.searchPlacesWithRetry(queries, location, radius, controller, retryCount + 1);
        }

        throw new Error(`Outscraper API error: ${errorMessage}`);
      }
      throw error;
    }
  }

  private formatCategoryForSearch(category: string): string {
    return category
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  private isWithinRadius(
    point: LatLngLiteral,
    center: LatLngLiteral,
    radiusMiles: number
  ): boolean {
    const distance = this.calculateDistance(center, point);
    const distanceInMiles = distance / 1609.34; // Convert meters to miles
    return distanceInMiles <= radiusMiles;
  }

  private calculateDistance(point1: LatLngLiteral, point2: LatLngLiteral): number {
    const R = 6371e3; // Earth's radius in meters
    const φ1 = point1.lat * Math.PI / 180;
    const φ2 = point2.lat * Math.PI / 180;
    const Δφ = (point2.lat - point1.lat) * Math.PI / 180;
    const Δλ = (point2.lng - point1.lng) * Math.PI / 180;

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    return R * c;
  }

  private async getAddressFromCoordinates(location: LatLngLiteral): Promise<string> {
    const geocoder = new google.maps.Geocoder();
    try {
      const result = await geocoder.geocode({
        location: location
      });

      if (result.results[0]) {
        // Try to get city and state first
        const postalCode  =  result.results[0].address_components.find(
            component => component.types.includes('postal_code')
        )?.short_name;
        const cityComponent = result.results[0].address_components.find(
          component => component.types.includes('locality')
        )?.long_name;
        const stateComponent = result.results[0].address_components.find(
          component => component.types.includes('administrative_area_level_1')
        )?.short_name;

        if (cityComponent && stateComponent) {
          return [cityComponent,stateComponent, postalCode].filter(c => !!c).join(', ');
        }

        // Fallback to formatted address
        return result.results[0].formatted_address;
      }
      throw new Error('No address found');
    } catch (error) {
      console.error('Geocoding failed:', error);
      // Fallback to coordinates if geocoding fails
      return `${location.lat.toFixed(6)}, ${location.lng.toFixed(6)}`;
    }
  }

  async searchByCategories(
    location: LatLngLiteral,
    radius: number,
    categories: string[]
  ): Promise<PlaceResult[]> {
    const address = await this.getAddressFromCoordinates(location);
    const allResults: OutscraperPlaceResult[] = [];
    const errors: string[] = [];
    let consecutiveErrors = 0;

    // Format search queries with address
    const searchQueries = categories.map(category =>
      `${this.formatCategoryForSearch(category)} near ${address}`
    );

    const controller = new AbortController();
    // Process in batches
    const promises : Array<() => Promise<void>> = [];
    for (let i = 0; i < searchQueries.length; i += this.BATCH_SIZE) {
      promises.push(async () => {
        try {
          const batchQueries = searchQueries.slice(i, i + this.BATCH_SIZE);
          const results = await this.searchPlacesWithRetry(batchQueries, location, radius, controller);

          if (results?.length > 0) {
            allResults.push(...results);
            consecutiveErrors = 0;
          }
        } catch (error) {
          consecutiveErrors++;
          const message = error instanceof Error ? error.message : 'Unknown error';

          errors.push(message);

          if (consecutiveErrors >= 3) {
            controller.abort();
            throw new Error(`Multiple search attempts failed. Please try again later: ${errors[0]}`);
          }
        }
      })
    }
    await Promise.all(promises.map(p => p()));

    if (allResults.length === 0 && errors.length > 0) {
      throw new Error(`No results found. Some searches failed: ${errors[0]}`);
    }

    // Process and return results
    const uniqueResults = Array.from(
      new Map(allResults.map(place => [place.place_id, place])).values()
    );

    return uniqueResults.map(place => ({
      place_id: place.place_id,
      google_id: place.google_id,
      name: place.name,
      formatted_address: place.full_address || [place.street, place.city, place.us_state].filter(a => !!a).join(', '),
      street: place.street ?? '',
      postal_code: place.postal_code,
      area_service: place.area_service,
      country_code: place.country_code ?? '',
      country: place.country ?? '',
      city: place.city ?? '',
      us_state: place.us_state ?? '',
      state: place.state ?? '',
      phone: place.phone || '',
      geometry: {
        location: {
          lat: place.latitude,
          lng: place.longitude
        }
      },
      rating: place.rating || 0,
      user_ratings_total: place.reviews || 0,
      category: Array.isArray(place.category) ? place.category : [place.category],
      distance: this.calculateDistance(
        location,
        { lat: place.latitude, lng: place.longitude }
      ),
      searchLocation: location,
      searchCategories: categories,
      searchRadius: radius,
      locationLink: place.location_link,
      located_in: place.located_in  ?? null,
      reviews: {
        fiveStarCount: place.reviews_per_score?.["5"] ?? 0,
      }
    } satisfies PlaceResult)).sort((a, b) => a.distance - b.distance);
  }
}
