import { useState, useCallback } from 'react';
import { GeocodingService } from '../services/geocoding';
import { OutscraperService } from '../services/outscraper';
import { PlaceResult, LatLngLiteral } from '@/types';
import {useAuth0} from "@auth0/auth0-react";
import {useLocation} from "react-router-dom";

export function useGoogleServices() {
  const user = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const browserLocation = useLocation();

  const searchPlaces = useCallback(async (
    location: LatLngLiteral,
    radius: number,
    categories: string[]
  ): Promise<PlaceResult[]> => {

    setLoading(true);
    setError(null);

    try {
      let accessToken: string;
      try {
        accessToken = await user.getAccessTokenSilently();
      } catch {
        await user.loginWithRedirect({
          appState: {
            returnTo: `${browserLocation.pathname}${browserLocation.search}${browserLocation.hash}`,
          },
        });
        setError('Error with user session');
        return [];
      }


      const outscraperService = new OutscraperService(accessToken);
      const results = await outscraperService.searchByCategories(location, radius, categories);

      if (results.length === 0) {
        setError('No results found for the selected categories');
      }

      return results;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An unexpected error occurred';
      setError(errorMessage);
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  const geocodeAddress = useCallback(async (address: string) => {
    try {
      const geocodingService = new GeocodingService();
      return await geocodingService.geocodeAddress(address);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to find location';
      setError(errorMessage);
      throw err;
    }
  }, []);

  return {
    loading,
    error,
    searchPlaces,
    geocodeAddress
  };
}
