import type { PlaceResult } from '@/types';
import JSZip from 'jszip';
import allCategories from '@/data/categories.json' assert { type: 'json' };

interface DealershipInfo {
  shortName: string;
  officialName: string;
}

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${month}-${day}-${year}`;
};

const formatPhoneNumber = (phone: string | undefined): string => {
  if (!phone) return '';

  // Remove all non-numeric characters
  const cleaned = phone.replace(/\D/g, '');

  // Remove +1 or 1 prefix if present
  const numbers = cleaned.startsWith('1') ? cleaned.slice(1) : cleaned;

  // Format as XXX-XXX-XXXX
  if (numbers.length === 10) {
    return `${numbers.slice(0, 3)}-${numbers.slice(3, 6)}-${numbers.slice(6)}`;
  }

  return '';
};

const extractLocationInfo = (address: string): string => {
  const parts = address.split(',').map(part => part.trim());
  if (parts.length >= 2) {
    const city = parts[parts.length - 2];
    const state = parts[parts.length - 1].split(' ')[0];
    return `${city} ${state}`;
  }
  return 'Unknown Location';
};

const generateFilename = (locationInfo: string, radius: number, rowCount: number, date: string): string => {
  return `Original Scraped Data -- 00 -- ORIGINAL -- ${locationInfo} -- ${date} -- ${radius} Mi -- ${rowCount} Rows`;
};

const createCategoriesCsv = (categories: string[]): string => {
  const headers = ['Applicable Class', 'All Categories Selected?', 'Google Business Category Searched'];

  const rows = categories.map(category => {
    const topCategory = allCategories.categories.find((c) => c.subcategories.find((sc) => sc.id === category));
    if (!topCategory) {
      return `"${category}"`;
    }
    const categoryName = topCategory.subcategories.find((sc) => sc.id === category);
    if (!categoryName) {
      return `"${topCategory.label}","","${category}"`;
    }
    const all = topCategory.subcategories.every(sc => categories.includes(sc.id));
    let allCategoriesSelected = all ? 'Yes' : 'No';
    if (topCategory.label === "Other") {
      allCategoriesSelected = 'Not Applicable';
    }
    return `"${topCategory.label}","${allCategoriesSelected}","${categoryName.label}"`;
  });
  return [headers.join(','), ...rows].join('\n');
};

export const downloadCSV = async (places: PlaceResult[], dealershipInfo?: DealershipInfo) => {
  const headers = [
    'ORIGINAL BPP Status Per Google',
    'Filename of Original Scraped Data File',
    'ORIGINAL Distance Limit Used in Search',
    'Google Business Category Selected by Company',
    'URL of Company\'s Google BPP',
    'Internal Code for Identifying the BPP URL in Google Contacts',
    'Longitude of Target Dealership',
    'Latitude of Target Dealership',
    'Name of Target Dealership - Short Name or Acronym',
    'Name of Target Dealership - Official Name',
    'Google Review Count',
    'Google Rating',
    'Radius Distance of Company from Target Dealership',
    'ORIGINAL Relevancy Assessment of Company',
    'Company Name',
    'ORIGINAL Complete Physical Address',
    'ORIGINAL Street Address - 1-2 Lines',
    'ORIGINAL City',
    'ORIGINAL State',
    'ORIGINAL ZIP',
    'Finalized Complete Physical Address',
    'Company is LOCATED IN According To Google',
    'Custom Website URL',
    'Main Phone',
    'Google ID',
    'Place ID',
    'ORIGINAL Number of 5-Star Google Reviews',
    'Longitude of Company',
    'Latitude of Company'
  ];


  // Get location info from the first result's address
  const locationInfo = places.length > 0 ?
    extractLocationInfo(places[0].formatted_address) :
    'Unknown Location';

  const date = formatDate(new Date());
  const radius = places[0]?.searchRadius || 0;
  const baseFilename = generateFilename(locationInfo, radius, places.length, date);

  const searchedCategories = places[0]?.searchCategories || [];
  const categoriesCsv = createCategoriesCsv(searchedCategories);

  const csvContent = [
    `"${headers.join('","')}"`,
    ...places.map(place => {
      const distanceInMiles = place.distance ? (place.distance / 1609.34).toFixed(2) : 'N/A';
      const formattedPhone = formatPhoneNumber(place.phone || place.formatted_phone_number);

      return '"' + [
        place.business_status || 'OPERATIONAL', // Business Page Profile Status
        baseFilename, // Original search filename
        place.searchRadius || '', // Original search radius
        place.category, // Google Business Categories
        `https://www.google.com/maps/place/?q=place_id:${place.place_id}`, // URL of Company's Google BPP
        "BPP", // Internal Code
        place.searchLocation?.lng || '', // Longitude of Target
        place.searchLocation?.lat || '', // Latitude of Target
        dealershipInfo?.shortName || '', // Short Name
        dealershipInfo?.officialName || '', // Official Name
        place.user_ratings_total || '0', // Google Review Count
        place.rating || 'N/A', // Google Rating
        distanceInMiles, // Radius Distance in miles
        'High', // Relevancy Assessment (all results are from direct category match)
        place.name || '',
        `${place.formatted_address}`, // ORIGINAL Complete Physical Address
        ``, // street address
        ``, // City
        ``, // State
        ``, // Zip
        ``, // Finalized Complete Physical Address
        `${place.located_in ?? ''}`,
        `${place.website || ''}`, // Custom Website URL
        `${formattedPhone}`, // Main Phone (formatted with tel: protocol)
        place.google_id || '', // Google ID
        place.place_id, // Google Place ID
        place.reviews.fiveStarCount,
        place.geometry.location.lng, // Longitude of Company
        place.geometry.location.lat // Latitude of Company
      ].join('","') + '"';
    })
  ].join('\n');

  // Create ZIP file
  const zip = new JSZip();
  zip.file(`${baseFilename}.csv`, csvContent);
  zip.file(`${baseFilename} - Categories.csv`, categoriesCsv);

  // Generate ZIP blob and download
  const blob = await zip.generateAsync({ type: 'blob' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', `${baseFilename}.zip`);
  link.style.visibility = 'hidden';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
